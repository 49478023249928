import React, { useRef, useState } from 'react';

import { isEmpty } from 'lodash-es';
import Link from 'next/link';
import { HiArrowNarrowRight } from 'react-icons/hi';
import {
  IoBriefcaseOutline,
  IoCarSportOutline,
  IoFootballOutline,
  IoPhonePortraitOutline,
  IoShirtOutline,
} from 'react-icons/io5';
import { MdOutlineRealEstateAgent } from 'react-icons/md';
import { RiBuilding2Line, RiHomeHeartLine } from 'react-icons/ri';
import { useEffectOnce } from 'usehooks-ts';

import {
  FilterRequestObject,
  SearchRequestObject,
} from '@/api/elasticsearch/models';
import { searchElasticApi } from '@/api/fetch/rest-search-api';
import { categories } from '@/lib/helpers';
import { CarouselEvents } from '@/modules/analytics/event-types';
import useRudderTrack from '@/modules/analytics/hooks/useRudderTrack';
import { CarouselType } from '@/modules/analytics/types';
import ClassifiedCard from '@/tayara-kit/ClassifiedCard/ClassifiedCard';
import ClassifiedCardSkel from '@/tayara-kit/Sekeletons/ClassifiedCardSkel';
import XCarousel from '@/tayara-kit/XCarousel';

import { PosterDesignElements } from './CarouselPoster';
import { useElasticSearchKeyCategories } from '../../hooks/useElasticSearchServer';
import { IListingItem } from '../../types';

export interface ICategory {
  id: string;
  label: string;
  icon: React.ElementType<any>;
  link: string;
  linkFr: string;
}

export interface ShopOfMonthCarouselMetadata extends PosterDesignElements {
  shopOfMonthId: string;
  shopOfWeekId: string;
  shopLabel: string;
  shopLink: string;
  ssrTag: string;
  title: string;
}

const categoryData: ICategory[] = [
  {
    label: 'Véhicules',
    icon: IoCarSportOutline,
    link: 'vehicles',
    linkFr: 'Véhicules',
    id: '60be84bc50ab95b45b08a094',
  },
  {
    label: 'Immobilier',
    icon: MdOutlineRealEstateAgent,
    link: 'real-estate',
    linkFr: 'Immobilier',
    id: '60be84bc50ab95b45b08a093',
  },
  {
    label: 'Multimédias',
    icon: IoPhonePortraitOutline,
    link: 'tech',
    linkFr: 'Informatique et Multimedias',
    id: '60be84bc50ab95b45b08a097',
  },
  {
    label: 'Maison & jardin',
    icon: RiHomeHeartLine,
    link: 'home-and-garden',
    linkFr: 'Pour la Maison et Jardin',
    id: '60be84bc50ab95b45b08a095',
  },
  {
    label: 'Loisirs',
    icon: IoFootballOutline,
    link: 'leisure-and-entertainment',
    linkFr: 'Loisirs et Divertissement',
    id: '60be84bc50ab95b45b08a096',
  },
  {
    label: 'Habillement',
    icon: IoShirtOutline,
    link: 'clothing-and-well-being',
    linkFr: 'Habillement et Bien Etre',
    id: '60be84bc50ab95b45b08a099',
  },
  {
    label: 'Emplois',
    icon: IoBriefcaseOutline,
    link: 'jobs-and-services',
    linkFr: 'Emploi et Services',
    id: '60be84bc50ab95b45b08a098',
  },
  {
    label: 'Entreprises',
    icon: RiBuilding2Line,
    link: 'Entreprises',
    linkFr: 'Entreprises',
    id: '60be84bd50ab95b45b08a09a',
  },
];

const ShowcasedShopOfWeekCarousel = ({
  ShopOfMonthCarouselMetadata,
  hitSsr,
}: {
  ShopOfMonthCarouselMetadata: ShopOfMonthCarouselMetadata;
  hitSsr: IListingItem[];
}) => {
  // const [handleCategoryClick] = useMeilisearchKeyCategories();
  const [handleCategoryClick] = useElasticSearchKeyCategories();
  const { rudderTrack, trackCarouselClassifiedClick } = useRudderTrack();

  const [isCompact, setIsCompact] = useState(false);
  const portalRef = useRef<HTMLDivElement>(null);

  const [scrollRef, setScrollRef] = useState<React.RefObject<HTMLDivElement>>({
    current: null,
  });
  const [showcasedShopOfMonthData, setShowcasedShopOfMonthCarouselData] =
    useState<IListingItem[]>();

  function getIconForMainCategory(
    categoryId: string,
    iconSize: number = 24
  ): JSX.Element {
    const category = categoryData.find((cat) => cat.id === categoryId);

    if (category) {
      const IconComponent = category.icon;
      return <IconComponent size={iconSize} className="text-primary" />;
    }

    switch (categoryId) {
      case '66c34239d16d7f3b0c2ec56e':
        return <MdOutlineRealEstateAgent size={iconSize} />;
      case '62a1bf06b71b9dd099a1d1c2':
        return <IoFootballOutline size={iconSize} />;
      case '60be84bd50ab95b45b08a09b':
        return <IoFootballOutline size={iconSize} />;
      default:
        return <IoFootballOutline size={iconSize} />;
    }
  }

  function getCategoryIcon(
    subcategoryId: string,
    iconSize: number = 24
  ): JSX.Element {
    const subcategory = categories.find((cat) => cat.id === subcategoryId);

    if (!subcategory) {
      return <></>;
    }

    const parentCategoryId = subcategory.parentcategoryxid;

    if (!parentCategoryId || parentCategoryId === '') {
      return getIconForMainCategory(subcategory.id, iconSize);
    }

    return getIconForMainCategory(parentCategoryId, iconSize);
  }
  const renderCategoryIcon = (iconSize: number = 24) => {
    const subcategoryId = showcasedShopOfMonthData
      ?.slice(0, 1)
      .map((classified) => classified.metadata?.subCategory)[0];

    if (!subcategoryId) {
      return <></>;
    }

    return getCategoryIcon(subcategoryId, iconSize);
  };

  const handleNavigation = () => {
    handleCategoryClick(ShopOfMonthCarouselMetadata?.shopOfMonthId || '');
    rudderTrack(CarouselEvents.CLICK_SHOP_OF_MONTH, {
      carouselType: ShopOfMonthCarouselMetadata,
      carouselDetails: ShopOfMonthCarouselMetadata,
    });
  };

  useEffectOnce(() => {
    if (isEmpty(hitSsr)) {
      const filterRequest: FilterRequestObject = {
        categoryId: '',
        subCategoryId: '',
        adParamsMap: {},
        rangeAdParamsMap: {},
        governorate: '',
        delegation: [''],
        minPrice: 0,
        maxPrice: 0,
        productTypeList: [],
        level: 0,
        state: 2,
        userid: ShopOfMonthCarouselMetadata?.shopOfMonthId,
      };
      const searchRequest: SearchRequestObject = {
        query: '',
        offset: 0,
        limit: 50,
        sort: 0,
        filter: filterRequest,
      };

      searchElasticApi(searchRequest, false).then(([[newHits, _]]) => {
        setShowcasedShopOfMonthCarouselData(newHits);
      });
    } else setShowcasedShopOfMonthCarouselData(hitSsr);
  });

  return (
    <div
      className="w-full mx-auto relative flex flex-col md:flex-row lg:flex-row gap-0 md:gap-10 lg:gap-6  items-center "
      ref={portalRef}
    >
      {/* TODO ADD A MARGIN LEFT */}
      {/* TODO FIX THE PERSPECTIVE */}
      {/* {
        <ShopOfMonthCarouselPoster
          shopLink={ShopOfMonthCarouselMetadata?.shopLink}
          handleNavigation={handleNavigation}
          scrollRef={scrollRef}
          isCompact={isCompact}
          posterDesignElements={omit(ShopOfMonthCarouselMetadata, [
            'shopOfMonthId',
          ])}
        />
      } */}
      {/* A la une carousel */}
      <div
        className="overflow-x-hidden h-full w-full py-auto mb-5"
        ref={scrollRef}
      >
        <div className="w-full lg:gap-x-28 flex justify-between  md:justify-between gap-y-4 my-2 lg:my-4">
          <div className="flex gap-2 justify-center items-center">
            {renderCategoryIcon(28)}

            <h2 className="text-base md:text-lg font-extrabold truncate">
              {showcasedShopOfMonthData
                ?.slice(0, 1)
                .map((classified) => classified.metadata?.publisher?.name)}
            </h2>
          </div>
          <Link
            href={`${ShopOfMonthCarouselMetadata?.shopLink}`}
            passHref
            onClick={handleNavigation}
            className="flex items-center gap-3 text-sm lg:text-base font-bold pl-3 md:ml-10 lg:mx-0 truncate text-neutral-800 underline"
          >
            Afficher tout <HiArrowNarrowRight size={20} />
          </Link>
        </div>

        <XCarousel
          setScrollRef={setScrollRef}
          portalEl={portalRef.current as Element}
          setIsCompact={setIsCompact}
          isCompact={isCompact}
        >
          <ul className="h-full flex gap-4 pt-4">
            {showcasedShopOfMonthData ? (
              showcasedShopOfMonthData.slice(0, 11).map((classified, index) => (
                <li
                  className="snap-start scroll-ml-px w-full"
                  key={classified.id}
                >
                  <ClassifiedCard
                    onClick={() =>
                      trackCarouselClassifiedClick(
                        CarouselEvents.CLICK_SHOP_OF_MONTH,
                        {
                          carousel_type: CarouselType.SHOP_OF_MONTH,
                          classified_details: classified,
                          classified_position: index,
                        }
                      )
                    }
                    classifiedCardData={classified}
                  />
                </li>
              ))
            ) : (
              <>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
              </>
            )}
          </ul>
        </XCarousel>
      </div>
    </div>
  );
};

export default ShowcasedShopOfWeekCarousel;
