import React from 'react';

import Link from 'next/link';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useWindowSize } from 'usehooks-ts';

import { rudderstackTrackEvent } from '@/api/rudderstack/client';

const ImmoxSection = () => {
  const windowSize = useWindowSize();

  return (
    <div className="relative w-full h-fit flex flex-wrap md:flex-nowrap justify-center items-center border-[1px] border-neutral-200 p-4 rounded-2xl gap-6 md:gap-10 bg-white">
      <div
        className=" relative w-[800px] h-[200px] md:h-[450px] md:w-[980px] lg:h-[360px]  m-2 overflow-hidden transition-transform inline-block rounded-xl bg-cover"
        style={{
          backgroundImage: `url(/media/immox.png)`,
        }}
      >
        <div className="absolute bottom-8 w-full h-fit flex items-center justify-center lg:justify-end lg:pr-10">
          <Link
            passHref
            href={'/immoNeuf'}
            aria-label="Découvrir immoExpert"
            onClick={() => rudderstackTrackEvent('Clicked on immoExpert logo')}
          >
            <img
              className=" ease-in-out cursor-pointer rounded-xl  w-[200px] md:w-[220px]"
              src={`/media/immoxxLogo.png`}
              alt={''}
            />
          </Link>
        </div>
      </div>

      <div
        className={`w-fit h-fit  flex flex-col justify-center items-center gap-0 md:gap-4 mx-auto ${
          windowSize.width < 1420 && 'md:absolute top-24 right-auto'
        }`}
      >
        <h2 className=" text-md md:text-xl font-extrabold uppercase text-white bg-neuf-100 text-center py-2 px-6 md:px-20 md:truncate  md:w-full">
          Tout L’immobilier Neuf en Tunisie en 1 Clic !
        </h2>
        <p className="bg-white/90 px-2 lg:px-10 py-2 text-sm md:text-xl font-bold text-center md:tracking-widest leading-8  w-[70vw] md:w-full ">
          Découvrez les biens immobiliers neufs{' '}
          <br className="hidden md:block" /> partout en Tunisie sur ImmoNeuf
        </p>
        <span className="relative w-fit flex rounded-xl bg-neuf-100 z-10 px-4 my-2 cursor-pointer border-2 border-white">
          <Link
            passHref
            href="https://www.tayara.tn/ads/c/ImmoNeuf/?page=1"
            aria-label="Découvrir immoExpert"
            onClick={() => rudderstackTrackEvent('Clicked on immoExpert logo')}
            className="flex items-center p-2 text-white"
          >
            <h3 className="mx-2 pb-[1px] text-white text-sm font-semibold truncate">
              Consultez
            </h3>
            <HiArrowNarrowRight
              className="animate-go-right mx-2 text-white"
              size={22}
            />{' '}
          </Link>
        </span>
        <Link
          passHref
          href={'/immoNeuf'}
          aria-label="Découvrir nos promoteurs"
          onClick={() => rudderstackTrackEvent('Clicked on immoExpert logo')}
          className="flex items-center p-2 "
        >
          <h3
            className={`mx-2 underline ${
              windowSize.width > 1420 ? 'text-neuf-100' : 'text-white'
            } pb-[1px]  text-base font-extrabold truncate`}
          >
            Découvrez nos promoteurs immobiliers
          </h3>
        </Link>
      </div>
    </div>
  );
};

export default ImmoxSection;
